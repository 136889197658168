// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('choiceGroups/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/choice_groups')
  return response.data.result
})

export const getData = createAsyncThunk('choiceGroups/getData', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/choice_groups?with_pagination=yes',
    params
  )
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data?.total,
    loading: true
  }
})

export const getUser = createAsyncThunk('choiceGroups/getUser', async id => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/choice_groups/${id}`)
  return response.data.data[0]
})

export const resetUser = createAsyncThunk('choiceGroups/resetUser', () => {
  return null
})

export const addUser = createAsyncThunk('choiceGroups/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/choice_groups', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().choiceGroups.params))
  return user
})
export const addItem = createAsyncThunk('choiceGroups/addItem', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/choice_items', params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.parent))
  await dispatch(getData(getState().choiceGroups.params))
  return user
})
export const updateUser = createAsyncThunk('choiceGroups/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/choice_groups/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().choiceGroups.params))
  return user
})
export const updateItem = createAsyncThunk('choiceGroups/updateItem', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/choice_items/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.parent))
  await dispatch(getData(getState().choiceGroups.params))
  return user
})
export const deleteUser = createAsyncThunk('choiceGroups/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/choice_groups/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().choiceGroups.params))
  return id
})

export const deleteItem = createAsyncThunk('choiceGroups/deleteItem', async (params, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/choice_items/${params.id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.parent))
  await dispatch(getData(getState().choiceGroups.params))
  return id
})

export const activateUser = createAsyncThunk('choiceGroups/activateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/choice_groups/${params.id}/activate`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().choiceGroups.params))
  return id
})

export const blockUser = createAsyncThunk('choiceGroups/blockUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/account/${params.id}/block`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().choiceGroups.params))
  return id
})

export const choiceGroupsSlice = createSlice({
  name: 'choiceGroups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default choiceGroupsSlice.reducer
