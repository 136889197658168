// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('restaurants/getAllData', async () => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/restaurants')
  return response.data.result
})

export const getData = createAsyncThunk('restaurants/getData', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/restaurants?with_pagination=yes',
    params
  )
  return {
    params,
    data: response.data.data.data,
    totalPages: response.data.data?.total,
    loading: true
  }
})

export const getOwners = createAsyncThunk('restaurants/getOwners', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/owners?with_pagination=no'
  )
  return {
    owners: response.data.data
  }
})
export const getChoices = createAsyncThunk('restaurants/getChoices', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/choice_groups?with_pagination=no'
  )
  return {
    choices: response.data.data
  }
})
export const getCategoryTypes = createAsyncThunk('restaurants/getCategoryTypes', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/categoryTypes?with_pagination=no'
  )
  return {
    catTypes: response.data.data
  }
})
export const getUser = createAsyncThunk('restaurants/getUser', async id => {
  const first = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/restaurants/${id}`)
  const mainId = first?.data?.data[0]?.menus[0]?.id
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/menus/${mainId}`)
  return response.data.data[0]
})
export const getOneItem = createAsyncThunk('restaurants/getOneItem', async id => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/items/${id}`)
  return response.data.data[0]
})
export const resetUser = createAsyncThunk('restaurants/resetUser', () => {
  return null
})
export const resetItem = createAsyncThunk('restaurants/resetItem', () => {
  return null
})
export const resetItems = createAsyncThunk('restaurants/resetItems', () => {
  return {
    items: [],
    itemLoading: false
  }
})
export const getCategories = createAsyncThunk('restaurants/getCategories', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/categories?with_pagination=no`,
    { params }
  )
  return {
    categories: response.data.data,
    totalCat: response.data.total,
    loading: true
  }
})
export const getItems = createAsyncThunk('restaurants/getItems', async params => {
  const response = await axios.get(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/items?with_pagination=no`, {
    params
  })
  return {
    items: response.data.data,
    itemLoading: true
  }
})

export const addUser = createAsyncThunk('restaurants/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/restaurants', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().restaurants.params))
  return user
})

export const addCategory = createAsyncThunk('restaurants/addCategory', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/categories', params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCategories({ menu_id: params.id }))
  return user
})

export const addItem = createAsyncThunk('restaurants/addItem', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/items', params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCategories({ menu_id: params.id }))
  return user
})
export const addVar = createAsyncThunk('restaurants/addVar', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/variations', params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getOneItem(params.parent))
  return params.user
})
export const updateUser = createAsyncThunk('restaurants/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/restaurants/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().restaurants.params))
  return user
})
export const updateMenu = createAsyncThunk('restaurants/updateMenu', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/menus/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.parent))
  return user
})
export const updateItem = createAsyncThunk('restaurants/updateItem', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/items/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getOneItem(params.id))
  await dispatch(getItems({ category_id: params.parent }))
  return params.user
})
export const updateVar = createAsyncThunk('restaurants/updateVar', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/variations/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getOneItem(params.parent))
  return params.user
})
export const updateChoices = createAsyncThunk('restaurants/updateChoices', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/variations/${params?.id}/choiceGroups`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getOneItem(params.parent))
  return params.user
})
export const updateCategory = createAsyncThunk('restaurants/updateCategory', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/categories/${params?.id}`, params.user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCategories({ menu_id: params.parent }))
  return user
})
export const deleteUser = createAsyncThunk('restaurants/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/restaurants/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().restaurants.params))
  return id
})
export const deleteItem = createAsyncThunk('restaurants/deleteItem', async (params, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/items/${params.id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getItems({ category_id: params.parent }))
  return id
})
export const deleteVar = createAsyncThunk('restaurants/deleteVar', async (params, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/variations/${params.id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getOneItem(params.parent))
  return params.id
})
export const deleteCategroy = createAsyncThunk('restaurants/deleteCategroy', async (params, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/categories/${params.id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getCategories({ menu_id: params.parent }))
  return id
})
export const activateUser = createAsyncThunk('restaurants/activateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/restaurants/${params.id}/activate`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().restaurants.params))
  return id
})
export const activateCategory = createAsyncThunk(
  'restaurants/activateCategory',
  async (params, { dispatch, getState }) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/categories/${params.id}/activate`, params.user)
      .then(res => {
        successAlert()
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          errorAlert(err?.response?.data?.message)
        } else {
          errorAlert(err)
        }
      })
    await dispatch(getCategories({ menu_id: params.parent }))
    return id
  }
)
export const activateMenu = createAsyncThunk('restaurants/activateMenu', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/menus/${params.id}/activate`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getUser(params.parent))
  // await dispatch(getData(getState().restaurants.params))
  return id
})
export const activateItem = createAsyncThunk('restaurants/activateItem', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/items/${params.id}/activate`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getItems({ category_id: params.parent }))
  return id
})
export const openUser = createAsyncThunk('restaurants/openUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/restaurants/${params.id}/open`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().restaurants.params))
  return id
})

export const blockUser = createAsyncThunk('restaurants/blockUser', async (params, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/account/${params.id}/block`, params.user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().restaurants.params))
  return id
})

export const restaurantsSlice = createSlice({
  name: 'restaurants',
  initialState: {
    data: [],
    total: 1,
    totalCat: 1,
    params: {},
    allData: [],
    owners: [],
    categories: [],
    choices: [],
    catTypes: [],
    items: [],
    selectedUser: null,
    selectedItem: null,
    loading: false,
    catLoading: false,
    itemLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getOwners.fulfilled, (state, action) => {
        state.owners = action.payload.owners
      })
      .addCase(getChoices.fulfilled, (state, action) => {
        state.choices = action.payload.choices
      })
      .addCase(getCategoryTypes.fulfilled, (state, action) => {
        state.catTypes = action.payload.catTypes
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getOneItem.fulfilled, (state, action) => {
        state.selectedItem = action.payload
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories
        state.totalCat = action.payload.totalCat
        state.catLoading = action.payload.loading
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.items = action.payload.items
        state.itemLoading = action.payload.itemLoading
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetItem.fulfilled, (state, action) => {
        state.selectedItem = action.payload
      })
      .addCase(resetItems.fulfilled, (state, action) => {
        state.items = action.payload.items
        state.itemLoading = action.payload.itemLoading
      })
  }
})

export default restaurantsSlice.reducer
