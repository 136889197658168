// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import notifications from './notification'
import reset from '@src/views/pages/authentication/store'
import orders from '@src/views/apps/orders/store'
import reviews from '@src/views/apps/reviews/store'
import users from '@src/views/apps/users/store'
import choiceGroups from '@src/views/apps/choice-groups/store'
import restaurants from '@src/views/apps/restaurants/store'
import roles from '@src/views/apps/roles/store'
import calendar from '@src/views/apps/calendar/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

const rootReducer = {
  auth,
  navbar,
  notifications,
  orders,
  roles,
  restaurants,
  choiceGroups,
  users,
  reviews,
  layout,
  calendar,
  reset,
  dataTables,
  permissions
}

export default rootReducer
