// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { successAlert, errorAlert } from '@alert'

export const getAllData = createAsyncThunk('roles/getAllData', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/RoleAndPermission/getRoles'
  )
  return response.data.result
})

export const getData = createAsyncThunk('roles/getData', async params => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/RoleAndPermission/getRoles?',
    {
      params
    }
  )
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: true
  }
})
export const getPermissions = createAsyncThunk('roles/getPermissions', async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/RoleAndPermission/getPermissions'
  )
  return response.data.data
})

export const getUser = createAsyncThunk('roles/getUser', async id => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/RoleAndPermission/getRoles/${id}`
  )
  return response.data.data[0]
})

export const resetUser = createAsyncThunk('roles/resetUser', () => {
  return null
})

export const addUser = createAsyncThunk('roles/addUser', async (user, { dispatch, getState }) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + '/api/dashboard/owner/RoleAndPermission/createRole', user, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().roles.params))
  return user
})

export const updateUser = createAsyncThunk('roles/updateUser', async (data, { dispatch, getState }) => {
  await axios
    .post(
      process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/RoleAndPermission/updateRole/${data?.id}`,
      data?.user,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().roles.params))
  return data.user
})

export const deleteUser = createAsyncThunk('roles/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(process.env.REACT_APP_BASE_URL + `/api/dashboard/owner/RoleAndPermission/deleteRole//${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      if (err?.response?.data?.message) {
        errorAlert(err?.response?.data?.message)
      } else {
        errorAlert(err)
      }
    })
  await dispatch(getData(getState().roles.params))
  // await dispatch(getAllData())
  return id
})

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    data: [],
    total: 1,
    totalQues: 1,
    totalInput: 1,
    params: {},
    allData: [],
    dataTypes: [],
    dataQues: [],
    questOptions: [],
    permissions: [],
    dataInput: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(resetUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default rolesSlice.reducer
